import { useEffect, useState } from "react";
import IDonation from "./IDonation";


export default function (props: { paypalOrderId: string, onlinePaymentReference: string, setStep: Function, onApprove: Function,onCancel:Function }) {
const [loading,setLoading]=useState<boolean>(false)
async function RenderPayPal() {


            const submitButton = document.getElementById("card-field-submit-button")!
            // Render each field after checking for eligibility
            const cardField = window.paypal.CardFields({
               

                createOrder: () => props.paypalOrderId,
                onApprove: props.onApprove,
                onError: ((err) => window.alert(err)),
                style: {
                    input: {
                        "font-size": "15px",
                        "font-family": "fs-albert",
                        "font-weight": "lighter",
                        color: "#ccc",
                    },
                    ".invalid": { color: "red" },
                }


            });

            if (cardField.isEligible()) {
                const nameField = cardField.NameField({
                    placeholder: "Full name",
                    style: { input: { color: "blue" } },

                });
                nameField.render("#card-name-field-container");


                const numberField = cardField.NumberField({
                    style: { input: { color: "blue" } },
                });
                numberField.render("#card-number-field-container");

                const cvvField = cardField.CVVField({
                    style: { input: { color: "blue" } },
                });
                cvvField.render("#card-cvv-field-container");

                const expiryField = cardField.ExpiryField({
                    style: { input: { color: "blue" } },
                });
                expiryField.render("#card-expiry-field-container");

                document.getElementById("card-field-submit-button")!
                    .addEventListener("click", () => {

                        cardField.getState().then((data)=>{
                            if(data.isFormValid)
                            {
                                setLoading(true)
                                cardField.submit().then(() => {
                                    
                                    //Submit success
                                }).finally(()=>setLoading(false)).catch((error) => {
                                    //Submit error
                                });
                            }
                            
                        })
                    });
            }






        }
    //renders paypal 
    useEffect(() => {

        

        if (props.onlinePaymentReference != null && props.paypalOrderId != null) {

            RenderPayPal().finally(() => {

                
            })
        }





    }, [])




    return (
        <>
            {props.paypalOrderId?<h4>Payment details</h4>:<h4>Loading...</h4>}

            <svg style={{ display: !loading ? "none" : "block", width: "200px" }} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 200" ><circle fill="#0C077B" stroke="#0C077B" stroke-width="2" r="10" cx="40" cy="30"><animate attributeName="cy" calcMode="spline" dur="2" values="65;135;65;" keySplines=".5 0 .5 1;.5 0 .5 1" repeatCount="indefinite" begin="-.4"></animate></circle><circle fill="#0C077B" stroke="#0C077B" stroke-width="2" r="10" cx="100" cy="65"><animate attributeName="cy" calcMode="spline" dur="2" values="65;135;65;" keySplines=".5 0 .5 1;.5 0 .5 1" repeatCount="indefinite" begin="-.2"></animate></circle><circle fill="#0C077B" stroke="#0C077B" stroke-width="2" r="10" cx="160" cy="65"><animate attributeName="cy" calcMode="spline" dur="2" values="65;135;65;" keySplines=".5 0 .5 1;.5 0 .5 1" repeatCount="indefinite" begin="0"></animate></circle></svg>






            <div id="card-form" className="card_container" style={{ display: loading ? "none" : "flex" }}>
                <div id="card-name-field-container"></div>
                <div id="card-number-field-container"></div>
                <div className="group">
                    <div id="card-expiry-field-container"></div>
                    <div id="card-cvv-field-container"></div>
                </div>




                <br /><br />
                <a style={{ display: props.paypalOrderId ? "flex" : "none" }} className="welcome-banner-link" id="card-field-submit-button">
                    <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 50 50" fill="none" >
                        <g filter="url(#filter0_ii_11_976)">
                            <path d="M9.09358 51.1679C14.8322 51.1679 20.5738 51.2574 26.3094 51.1381C30.7542 51.0457 34.889 49.9006 38.2368 46.707C40.1119 44.9179 41.2865 42.6993 42.3686 40.3973C44.7237 35.3937 47.1264 30.411 49.4785 25.4044C49.6306 25.0794 49.6455 24.5635 49.4934 24.2444C46.7896 18.5013 44.1334 12.7284 41.2894 7.05384C38.9374 2.36035 34.9665 -0.275644 29.8182 -1.16723C27.4095 -1.58171 24.9858 -1.68011 22.5473 -1.53997C22.3982 -1.53102 22.2492 -1.534 22.1001 -1.534C12.5844 -1.51313 3.07176 -1.49226 -6.4439 -1.4684C-7.21303 -1.4684 -7.98513 -1.41771 -8.75426 -1.45349C-9.267 -1.47735 -9.54127 -1.31931 -9.76485 -0.83326C-11.7711 3.59485 -13.211 8.19888 -14.1083 12.9759C-15.2769 19.1931 -15.4915 25.4521 -14.8268 31.7379C-14.1381 38.2235 -12.4955 44.566 -9.77677 50.5089C-9.54425 51.0188 -9.26701 51.1828 -8.7155 51.1798C-2.78014 51.159 3.15821 51.1679 9.09358 51.1679C9.09358 51.2037 9.09358 51.1321 9.09358 51.1679Z" fill="#59D8A1"></path>
                        </g>
                        <defs>
                            <filter id="filter0_ii_11_976" x="-17.1997" y="-1.59988" width="66.8" height="52.8" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                                <feFlood flood-opacity="0" result="BackgroundImageFix"></feFlood>
                                <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"></feBlend>
                                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"></feColorMatrix>
                                <feOffset dx="-2"></feOffset>
                                <feGaussianBlur stdDeviation="1.5"></feGaussianBlur>
                                <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"></feComposite>
                                <feColorMatrix type="matrix" values="0 0 0 0 0.105882 0 0 0 0 0.156863 0 0 0 0 0.25098 0 0 0 0.2 0"></feColorMatrix>
                                <feBlend mode="normal" in2="shape" result="effect1_innerShadow_11_976"></feBlend>
                                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"></feColorMatrix>
                                <feOffset dx="-1"></feOffset>
                                <feGaussianBlur stdDeviation="0.5"></feGaussianBlur>
                                <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"></feComposite>
                                <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0"></feColorMatrix>
                                <feBlend mode="normal" in2="effect1_innerShadow_11_976" result="effect2_innerShadow_11_976"></feBlend>
                            </filter>
                        </defs>
                    </svg>
                    Complete Payment
                </a>

                <label className="paypalstatement">By paying with your card, you acknowledge that your data will be processed by PayPal subject to the PayPal Privacy Statement available at PayPal.com</label>
            </div></>
    )
}