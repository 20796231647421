import  { useEffect, useState } from "react"
import IDonation from "./IDonation"


export default function Step2(props: { donation: IDonation, setStep: Function, setDonorDetails: Function }) {

  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    // Trigger the visibility change when the component is mounted
    setIsVisible(true);
  }, []);
  return (
    <div className={`container ${isVisible ? 'visible' : ''}`}>
      <h4>Please donate today</h4>
      <p className="accent">Support us to reach our goals</p>
      <span>{props.donation.DonationAmount != 0 ? <>You have chosen to donate £{props.donation.DonationAmount}
        <a
          href=""
          className="minorlink"
          onClick={(e) => {
            e.preventDefault()
            props.setStep("1")
          }
          }
        >Change</a></> : null}</span>

      <h3>Your Details</h3>
      <form  onSubmit={(e)=>
        {
          e.preventDefault()
          props.setStep("3")
        }
        } >
        <div className="form-input">
          <div className="labels">
            <span>First Name</span>
            <span>Required</span>
          </div>

          <input type="text" className="input" placeholder="Enter your first name"
          required
            maxLength={35}
            value={props.donation.Donor.FirstName}
            onChange={(e) => {
              props.setDonorDetails({ ...props.donation.Donor, FirstName: e.target.value })
            }
            }

          ></input>
        </div>
        <div className="form-input">
          <div className="labels">
            <span>Last Name</span>
            <span>Required</span>
          </div>

          <input type="text" className="input" placeholder="Enter your last name"
          required
            value={props.donation.Donor.LastName}
            maxLength={35}
            onChange={(e) => {
              props.setDonorDetails({ ...props.donation.Donor, LastName: e.target.value })
            }
            }

          ></input>
        </div>
        <div className="form-input">
          <div className="labels">
            <span>Email</span>
            <span>Required</span>
          </div>

          <input type="email" className="input" placeholder="Enter your email address"
          required
            maxLength={35}
            value={props.donation.Donor.Email}
            onChange={(e) => {
              props.setDonorDetails({ ...props.donation.Donor, Email: e.target.value })
            }
            }

          ></input>
        </div>

        <div className="form-input">
          <div className="labels">
            <span>GMC No</span>
            <span>Optional</span>
          </div>

          <input type="text"  className="input" placeholder="Enter your GMC Number"
          
            maxLength={7}
            value={props.donation.Donor.Gmc}
            onChange={(e) => {
              props.setDonorDetails({ ...props.donation.Donor, Gmc: e.target.value })
            }
            }

          ></input>
        </div>
        <button 
       
        type="submit" className="link" >Next <svg width="20px" height="20px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" strokeWidth="0"></g><g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M6 12H18M18 12L13 7M18 12L13 17" stroke="#000000" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path> </g></svg></button>
      </form>






    </div>
  )
}