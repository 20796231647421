import { useEffect, useState } from "react";
import IDonation from "./IDonation"



export default function BankDetails(props: { donation: IDonation, setStep: Function, setReferences: Function }) {
    const [isVisible, setIsVisible] = useState(false);

    useEffect(()=>{
        setIsVisible(true)

        


    },[])
    return (
        <div className={`container ${isVisible ? 'visible' : ''}`}>
            <h4>Please donate today</h4>
            <p className="accent">Support us to reach our goals</p>


            <span>{props.donation.DonationAmount != 0 ? <>You have chosen to donate £{props.donation.DonationAmount}
                <a
                    href=""
                    className="minorlink"
                    onClick={(e) => {
                        e.preventDefault()
                        props.setReferences({ paypalOrderId: null, onlinePaymentReference: null, crmPaymentTransactionId: null })
                        props.setStep("1")
                    }
                    }
                >Change</a></> : null}</span>

            
            <p className="greenBox">
                <span className="accent">Please send your donation to:</span>
                <br></br>
                <br></br>
                <span className="important">Beneficiary: Royal College of Physicians</span>
                <br></br>
                <br></br>
                <span className="important">Sort Code: 09-02-22</span>
                
                <br></br>
                <br></br>
                <span className="important">Account Number: 10915015</span>
                
                <br></br>
                <br></br>
                <span className="important">IBAN Number: GB44ABBY09022210915015</span>
                
                <br></br>
                <br></br>
                <span className="important">BIC/Swift Code: ABBYGB2LXXX</span>
                
                <br></br>
                <br></br>
                <span className="important">Payment Reference: {props.donation.Donor.OnlinePaymentReference} - ECSACOP</span>
                <br></br>
                <br></br>
                <span className="important">Bank Address: Santander</span>
                <br></br>
                
                <span className="important">Bridle Roal</span>
                <br></br>
               
                <span className="important">Bootle</span>
                <br></br>
             
                <span className="important">Merseyside</span>
                <br></br>
             
                <span className="important">L30 4GB</span>


            </p>

            <a className="link" href="" onClick={(e)=>
                {
                    e.preventDefault()
                    props.setStep("2")
                }
            }>Try again with using your card <svg width="20px" height="20px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" strokeWidth="0"></g><g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M6 12H18M18 12L13 7M18 12L13 17" stroke="#000000" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path> </g></svg></a>



        </div>
    )
}