import { useEffect, useState } from "react"
import IDonation from "./IDonation";
import Checkout from "./Checkout";



export default function Step4(props: { donation: IDonation, setStep: Function, setReferences: Function }) {
    const [isVisible, setIsVisible] = useState(false);
    const [loading, setLoading] = useState<boolean>(true)
    const [error,setError]=useState<string|null>(null)
  






    async function onApproveCallback(data, actions) {
        try {
            const { liabilityShift, orderID } = data;
            
            const response = await fetch(process.env.REACT_APP_CRM_BASE+`/CaptureOrder?orderId=${data.orderID}&liabilityShift=${liabilityShift}`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
            });

            let orderData = await response.json();
            orderData = orderData.success
           
            if (orderData) {
                props.setStep("5")
            }
            else {
                setError("We are sorry, your card payment was declined. You may try again or donate via bank transfer")

                props.setReferences({ paypalOrderId: null, onlinePaymentReference: null })
                const response = await fetch(process.env.REACT_APP_CRM_BASE+`/Retry`, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body:JSON.stringify(props.donation)
                });
    
                let orderRetryData = await response.json();
                
                if(orderRetryData.success)
                {
                    
                    props.setReferences({ paypalOrderId: orderRetryData.data.paypalOrderId, onlinePaymentReference: orderRetryData.data.onlinePaymentReference,crmPaymentTransactionId:orderRetryData.data.crmPaymentTransactionId})
                }
            }

        } catch (error) {
            
            window.alert(
                `Sorry, your transaction could not be processed...<br><br>${error}`
            );
        }
    }

    var CreateOrder = async () => {



        if (props.donation.Donor.PaypalOrderId == null || props.donation.Donor.OnlinePaymentReference == null || props.donation.Donor.CrmPaymentTransactionId == null) {
            const response = await fetch(process.env.REACT_APP_CRM_BASE+"/CreateOrder", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                // use the "body" param to optionally pass additional order information
                // like product ids and quantities
                body: JSON.stringify(props.donation),
            });

            const orderData = await response.json()
            props.setReferences(orderData)
           




        }


    }

    var ShowError=()=>
    {
        if(error!=null)
        {
            return(
                <div className="errorBanner">
                    <p>{error} <a href=""
                    onClick={(e)=>
                    {
                        e.preventDefault()
                        
                        setError(null)
                        props.setStep("6")
                        
                    }
                    }
                    >Show instructions</a></p>
                    <div><svg 
                    onClick={()=>{
                        setError(null)
                    }}
                    style={{cursor:"pointer"}}
                    width="24px" height="24px" viewBox="-0.5 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M3 21.32L21 3.32001" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path> <path d="M3 3.32001L21 21.32" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path> </g></svg></div>
                </div>

            )
        }
    }

    var onCancel=()=>
    {
        setError("Card authentication cancelled, please try again or donate by bank payment")
    }

  

    //get order
    useEffect(()=>{
        setIsVisible(true)

        CreateOrder().finally(()=>{})


    },[])


    

 
   




    return (
        <div className={`container ${isVisible ? 'visible' : ''}`}>
            <h4>Please donate today</h4>
            <p className="accent">Support us to reach our goals</p>


            <span>{props.donation.DonationAmount != 0 ? <>You have chosen to donate £{props.donation.DonationAmount}
                <a
                    href=""
                    className="minorlink"
                    onClick={(e) => {
                        e.preventDefault()
                        props.setReferences({ paypalOrderId: null, onlinePaymentReference: null,crmPaymentTransactionId:null})
                        props.setStep("1")
                    }
                    }
                >Change</a></> : null}</span>

                {ShowError()}

                

                

                
                {props.donation.Donor.PaypalOrderId!=null && props.donation.Donor.OnlinePaymentReference!=null?
                <Checkout 
                paypalOrderId={props.donation.Donor.PaypalOrderId}
                setStep={props.setStep}
                onlinePaymentReference={props.donation.Donor.OnlinePaymentReference}
                onApprove={onApproveCallback}
                onCancel={onCancel}
                 />:
                 <svg style={{ display: props.donation.Donor.PaypalOrderId ? "none" : "block", width: "200px" }} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 200" ><circle fill="#0C077B" stroke="#0C077B" stroke-width="2" r="10" cx="40" cy="30"><animate attributeName="cy" calcMode="spline" dur="2" values="65;135;65;" keySplines=".5 0 .5 1;.5 0 .5 1" repeatCount="indefinite" begin="-.4"></animate></circle><circle fill="#0C077B" stroke="#0C077B" stroke-width="2" r="10" cx="100" cy="65"><animate attributeName="cy" calcMode="spline" dur="2" values="65;135;65;" keySplines=".5 0 .5 1;.5 0 .5 1" repeatCount="indefinite" begin="-.2"></animate></circle><circle fill="#0C077B" stroke="#0C077B" stroke-width="2" r="10" cx="160" cy="65"><animate attributeName="cy" calcMode="spline" dur="2" values="65;135;65;" keySplines=".5 0 .5 1;.5 0 .5 1" repeatCount="indefinite" begin="0"></animate></circle></svg>
}

           








        </div>
    )
}