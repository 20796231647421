import  { useEffect, useState } from "react"
import IDonation from "./IDonation"
import AddressSearch from 'react-loqate'





export default function Step3(props: { donation: IDonation, setStep: Function, setGifaid: Function, setAddress: Function }) {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    // Trigger the visibility change when the component is mounted
    setIsVisible(true);
    if(document.getElementsByClassName("react-loqate-input").length>0)
    {
    
    document.getElementsByClassName("react-loqate-input")[0].placeholder="Start typing your address"
    }
  }, [props.donation.Donor.Address,props.donation.Donor.GiftAid]);

  function RenderNextButton()
  {
    if(props.donation.Donor.GiftAid==true)
    {
      let address=props.donation.Donor.Address;
      if(address!=null && address.country=="GB" && address.postcode!=null && address.postcode!="" )
      {
        return(
          <a
          onClick={(e)=>{
            e.preventDefault()
            props.setStep("4")
          }}
          href="" className="link">Next <svg width="20px" height="20px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M6 12H18M18 12L13 7M18 12L13 17" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path> </g></svg></a>
        )
      }
    }
    else
    {
      return <a
      onClick={(e)=>{
        e.preventDefault()
        props.setStep("4")}}
      className="link" href="">Continue without giftaid <svg width="20px" height="20px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M6 12H18M18 12L13 7M18 12L13 17" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path> </g></svg></a>
    }
  }



  return (
    <div className={`container ${isVisible ? 'visible' : ''}`}>



      <span>{props.donation.DonationAmount != 0 ? <>You have chosen to donate £{props.donation.DonationAmount}
        <a
          href=""
          className="minorlink"
          onClick={(e) => {
            e.preventDefault()
            props.setStep("1")
          }
          }
        >Change</a></> : null}</span>

      <img className="giftaid" alt="as" />
      <p id="giftaidmessage">Make your donation of <span className="highlight">£{props.donation.DonationAmount}</span> worth <span className="highlight">£{props.donation.DonationAmount * 1.25} </span> at no extra cost to you.</p>

      <div className={props.donation.Donor.GiftAid ? "selectionbox selectedbox" : "selectionbox"}>
        <div className="flag">


        </div>
        <div className="selectiontext"
          onClick={(e) => {
            props.setGifaid(true)
          }
          }
        >
          <div className="title">
            Yes

          </div>
          <div className="text">
            I am a UK tax payer and I understand that if the Gift Aid claimed on this and other donations I make this tax year is more than the tax I pay on my income and capital gains it will be my responsibility to pay the difference to HMRC.

          </div>
        </div>
      </div>

      <div className={props.donation.Donor.GiftAid == false ? "selectionbox selectedbox" : "selectionbox"}
        onClick={(e) => {
          props.setGifaid(false)
        }
        }
      >
        <div className="flag">


        </div>
        <div className="selectiontext">
          <div className="title">
            No

          </div>
          <div className="text">
            Continue without giftaid

          </div>
        </div>
      </div>


      {props.donation.Donor.GiftAid ?
        <div className="form-input">
          <div className="labels">
            <span>Address</span>
            <span>Required</span>
          </div>
          {props.donation.Donor.Address.postcode!=null && props.donation.Donor.Address.country!=""?
          <span className="addresslabel">
            {props.donation.Donor.Address.label} <a href="" className="minorlink"
            onClick={(e)=>{
              e.preventDefault()
              props.setAddress({label: null,
                addressLine1: null,
                addressLine2: null,
                adminArea1: null,
                adminArea2: null,
                postcode: null,
                country: null})
            }}><svg width="25px" height="25px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M18.3785 8.44975L8.9636 17.8648C8.6844 18.144 8.3288 18.3343 7.94161 18.4117L4.99988 19.0001L5.58823 16.0583C5.66566 15.6711 5.85597 15.3155 6.13517 15.0363L15.5501 5.62132M18.3785 8.44975L19.7927 7.03553C20.1832 6.64501 20.1832 6.01184 19.7927 5.62132L18.3785 4.20711C17.988 3.81658 17.3548 3.81658 16.9643 4.20711L15.5501 5.62132M18.3785 8.44975L15.5501 5.62132" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path> </g></svg></a>
          </span>
          
        :
        

          <AddressSearch
            locale="en-GB"
            countries={["GB"]}
            
            apiKey="FH88-KF94-HE83-XF31"
            onSelect={(address) => {
              props.setAddress({ postcode: address.PostalCode, label: address.Label, country: address.CountryIso2,addressLine1:address.Line1,addressLine2:address.Line2,adminArea1:address.City,adminArea2:address.Province })
            
            }
            }
            
            limit={5}


          />
          }
        </div>

        :
        null}

{props.donation.Donor.GiftAid?<p style={{fontSize:"13px"}} className="accent">Please notify the charity if you want to cancel this declaration, change your name or home address or no longer pay sufficient tax on your income and/or capital gains.</p>:null}


        
          {RenderNextButton()}






    </div>
  )
}