import { useEffect, useState } from "react";
import IDonation from "./IDonation";
import Confetti from 'react-confetti'




export default function Success(props: { donation: IDonation }) {
    const [isVisible, setIsVisible] = useState(false);


    useEffect(() => {
        // Trigger the visibility change when the component is mounted
        setIsVisible(true);
    }, [])



    return (
        <div className={`container ${isVisible ? 'visible' : ''}`}>
            <svg width="100" height="100" viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M49.9998 95.8334C75.3129 95.8334 95.8332 75.3131 95.8332 50.0001C95.8332 24.687 75.3129 4.16675 49.9998 4.16675C24.6868 4.16675 4.1665 24.687 4.1665 50.0001C4.1665 75.3131 24.6868 95.8334 49.9998 95.8334Z" fill="#5BB543" />
                <path d="M29.1665 56.2501L41.4936 66.4938L70.8332 35.4167" stroke="white" stroke-width="12.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
            </svg>

            <span>Thank you for your generous donation</span>
            <p className="accent" style={{textAlign:"center"}}>
                Your support is contributing to training much needed doctors to deliver life saving treatment
            </p>

            <a className="link" href="https://rcp.ac.uk">Visit our main website</a>

        </div>
    )
}