import { useEffect, useState } from "react"


export default function Step1(props: { options: number[], donationAmount: number, updateAmount: Function, setStep: Function }) {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    // Trigger the visibility change when the component is mounted
    setIsVisible(true);
  }, []);


  function RenderDonationDescriptionAmount() {

    switch (props.donationAmount) {
      case 50:
        {
          return "£50 – Could contribute to buying vital training equipment";
        }
        case 100:
        {
          return "£100 – Could contribute to staff costs to deliver crucial ECSACOP training";
        }
        case 200:
        {
          return "£200 – Could cover one year of examination fees for a trainee";
        }
        case 400:
          {
            return "£400 – Could cover all course examination fees for a trainee";
          }
          case 800:
          {
            return "£800 – Could cover course fees for a trainee for a year ";
          }

      default:
        break;
    }
  }

  return (
    <div className={`container ${isVisible ? 'visible' : ''}`}>
      <h4>Please donate today</h4>
      <p className="accent">Support us to reach our goals</p>
      <span>{props.donationAmount != 0 && props.donationAmount!=null ? <>You have chosen to donate £{props.donationAmount}</> : null}</span>
      {props.donationAmount!=0?<span style={{padding: '20px',
textAlign: 'center'}}>{RenderDonationDescriptionAmount()}</span>:null}
      <div className="donation-options-container">

        {props.options.map((item, index) => {
          return (
            <span key={index}
              onClick={(e) => {
                props.updateAmount(item)
              }
              }
              className={props.donationAmount == item ? "amount selected" : "amount"}>£{item}</span>
          )
        })}

        <input
          className="amount"
          type="text"
          value={props.donationAmount === 0 ? '' : props.donationAmount}
          placeholder="Other"
          max={5000}
          onChange={(e) => {
            const inputValue = e.target.value;
            const regex = /^\d+(\.\d{0,2})?$/;

            // Allow input only if it matches the regex or is an empty string
            if (inputValue === '' || (regex.test(inputValue) && Number(inputValue) > 0)) {
              if (Number(inputValue) > 5000) {
                props.updateAmount(5000); // Enforce max limit
              } else {
                props.updateAmount(Number(inputValue)); // Update amount
              }
            }
          }}
        />



      </div>
      {props.donationAmount > 0 ?
        <a

          onClick={(e) => {
            e.preventDefault()
            if (props.donationAmount > 0) {
              props.setStep("2")
            }
            else {
              alert("Donation amount needs to be greater than £1")
            }
          }}

          href="" className="link">Next <svg width="20px" height="20px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" strokeWidth="0"></g><g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M6 12H18M18 12L13 7M18 12L13 17" stroke="#000000" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path> </g></svg></a>
        : null}
    </div>
  )
}