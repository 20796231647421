import  { useEffect, useState } from "react";
import IDonation from "./Components/DonationComponent/IDonation";
import Step1 from "./Components/DonationComponent/Step1";
import Step2 from "./Components/DonationComponent/Step2";
import Step3 from "./Components/DonationComponent/Step3";
import Step4 from "./Components/DonationComponent/Step4";
import Success from "./Components/DonationComponent/Success";
import BankDetails from "./Components/DonationComponent/BankDetails";



export function App(props:{fundId:string, appealId:string}) {
let emptyDonation:IDonation={
  DonationAmount: null,
  Donor: {
    RcpCode: null,
    FirstName: null,
    LastName: null,
    Email: null,
    Gmc:null,
    CrmMatched: false,
    Address: {
      label: null,
      addressLine1: null,
      addressLine2: null,
      adminArea1: null,
      adminArea2: null,
      postcode: null,
      country: null
    },
    GiftAid: true,
    FundId: props.fundId,
    AppealId: props.appealId,
    PaypalOrderId: null,
    OnlinePaymentReference: null,
    CrmPaymentTransactionId:null
  }
}
  let options=[50,100,200,400,800]
  const [donation,setDonation]=useState<IDonation>(emptyDonation)
  const [step,setStep]=useState<"1"|"2"|"3"|"4"|"5"|"6">("1")



    switch (step) {
      case "1":
        {
          return <Step1 
          options={options} 
          donationAmount={donation.DonationAmount} 
          updateAmount={(e)=>setDonation({...donation,DonationAmount:e})}
          setStep={setStep} />
        
        }
        case "2":
        {
          return <Step2 donation={donation} setStep={setStep} setDonorDetails={(e)=>setDonation({...donation,Donor:e})} />
        
        }
        case "3":
        {
          return <Step3 
          donation={donation} 
          setStep={setStep} 
          setGifaid={(e:boolean)=>setDonation({...donation,Donor:{...donation.Donor,GiftAid:e}})}
          setAddress={(e:IDonation["Donor"]['Address'])=>setDonation({...donation,Donor:{...donation.Donor,Address:e}})}
          />
        
        }

        case "4":
        {
          return <Step4  
          donation={donation} 
          setStep={setStep} 
          setReferences={(e)=>setDonation({...donation,Donor:{...donation.Donor,OnlinePaymentReference:e.onlinePaymentReference,PaypalOrderId:e.paypalOrderId,CrmPaymentTransactionId:e.crmPaymentTransactionId}})}
         

          />
        
        }
        case "5":
        {
          return <Success donation={donation} />
        
        }
        case "6":
        {
          return <BankDetails 
          donation={donation} 
          setStep={setStep} 
          setReferences={(e)=>setDonation({...donation,Donor:{...donation.Donor,OnlinePaymentReference:e.onlinePaymentReference,PaypalOrderId:e.paypalOrderId,CrmPaymentTransactionId:e.crmPaymentTransactionId}})}
 />
        
        }
    
      default:
        {
        break;
        }
    }
  }